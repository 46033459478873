/*
=============== 
Variables
===============
*/

:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(184, 91%, 17%);
  --clr-primary-2: hsl(185, 84%, 25%);
  --clr-primary-3: hsl(185, 81%, 29%);
  --clr-primary-4: hsl(184, 77%, 34%);
  /* primary/main color */
  --clr-primary-5: hsl(185, 62%, 45%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(185, 57%, 50%);
  --clr-primary-7: hsl(184, 65%, 59%);
  --clr-primary-8: hsl(184, 80%, 74%);
  --clr-primary-9: hsl(185, 94%, 87%);
  --clr-primary-10: hsl(186, 100%, 94%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --ff-primary: 'Lato', sans-serif;
  --transition: all 0.3s linear;
  --spacing: 0.25rem;
  --radius: 0.5rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}
/*
=============== 
Global Styles
===============
*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  background: var(--clr-white);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
img:not(.nav-logo) {
  /* width: 100%; */
   display: block; 
}



h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-5);
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
/*  global classes */

.btn {
  text-transform: uppercase;
  background: var(--clr-primary-5);
  color: var(--clr-white);
  padding: 0.375rem 0.75rem;
  letter-spacing: var(--spacing);
  display: inline-block;
  /* font-weight: 700; */
  transition: var(--transition);
  font-size: 0.875rem;
  border: 2px solid transparent;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}
.btn:hover {
  color: var(--clr-primary-1);
  background: var(--clr-primary-8);
}

/* section */
.section {
  padding: 5rem 0;
  scroll-margin-top: 4rem;
}
.section-title {
  text-align: center;
  margin-bottom: 4rem;
}
.section-title h2 {
  text-transform: uppercase;
}
.section-title span {
  color: var(--clr-primary-5);
}
.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
  /* border: 2px solid blue; */
}
@media screen and (min-width: 992px) {
  .section-center {
    width: 95vw;
  }
}

/*
=============== 
Header
===============
*/

.header {
  min-height: 100vh;
  /* background: var(--clr-primary-5); */
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgb(44, 174, 186, 0.7), rgba(0, 0, 0, 0.7)),
      url('./assets/moraine1.jpg') center/cover no-repeat;
}
  
.header-banner {
  text-align: center;
  color: var(--clr-white);
  padding: 0 3rem;
}
.header-banner h1 {
  text-transform: uppercase;
}
.header-banner p {
  max-width: 35rem;
  margin-left: auto;
  margin-right: auto;
  color: var(--clr-white);
  letter-spacing: var(--spacing);
}
.header-btn {
  padding: 0.9rem 1.6rem;
  font-size: 1.25rem;
  background: var(--clr-white);
  color: var(--clr-primary-5);
}
.header-btn:hover {
  background: transparent;
  color: var(--clr-white);
  border-color: var(--clr-white);
}
@media screen and (min-width: 768px) {
  .header {
    /* background: linear-gradient(rgb(44, 174, 186, 0.7), rgba(0, 0, 0, 0.7)),
      url('./assets/moraine1.jpg') center/cover no-repeat; */
  }
  .header-banner {
    padding: 0;
  }
  .header-banner p {
    max-width: 45rem;
  }
}
/*
=============== 
About
===============
*/
/* section added to globals */
/* title added to globals */
/* section center added to globals */

.about-info {
  /* background-color: var(--clr-grey-10); */
  /* flex: 1; */
  /* margin-bottom: 2rem; */
  /* height: 50vh; */
}
.about-info h3 {
  text-align: center;
  text-decoration:underline;
  text-transform: none;
  margin-bottom: 2rem;
  color: var(--clr-grey-1);
}

.about-info p{
  text-indent: 30px;
  color: var(--clr-grey-2);
  /* font-size: larger;  */
}

/* .about-img img{
 
  box-shadow: 10px 10px 30px 0px grey;
  width: 80%;
  height: auto;
  
} */

#about{
  /* background-color: black;
  color: #fff; */
}

.about-center {
  display: flex;
  gap: 2rem;
  /* margin-bottom: 2rem; */
}

@media screen and (min-width: 1px) {
  .about-center {
    flex-direction: column;
    
  }

  .about-info {
    order: 2;
  }



  .about-img {
    
    order: 1;
  }

  .about-img img{
  /* border: 5px solid darkcyan; */
  box-shadow: 0px 0px 0px 0px;
  width: 60%;
  height: auto;
  margin: auto;
  /* box-shadow: 1px 1px 4px var(--clr-grey-1); */
}

  
}



@media screen and (min-width: 992px) {
  .about-center {
    /* display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem; */
    flex-direction: row;
    padding-left: 5rem;
  }

  .about-info {
    order: 1;
  }

  .about-info p{
    font-size: larger;
  }

  .about-img {
    order: 2;
  }

  .about-img img{
    /* border: 5px solid darkcyan; */
    box-shadow: 10px 10px 30px 0px grey;
    width: 130%;
    height: auto;
    margin-left: 4rem;
    /* box-shadow: 1px 1px 4px var(--clr-grey-1); */
  }

  .about-img,
  .about-info {
    /* margin-bottom: 0; */
  }
}
@media screen and (min-width: 1170px) {
  .about-img::before {
    /* content: '';
    position: absolute;
    width: 100%;
    height: 100%;
     border: 0.5rem solid var(--clr-primary-5); 
    box-sizing: border-box;
    top: -1.5rem;
    left: -1.5rem; */
  }

  /* .about-img {
    position: relative;
  }
  .about-photo {
    position: relative;
  } */
}
/*
=============== 
Skills
===============
*/
.skills {
  background: var(--clr-grey-10);
}
.skill {
  /* text-align: center; */
  /* margin-bottom: 3rem; */
  
}
/* .skill-icon {
  background: var(--clr-primary-5);
  color: var(--clr-primary-1);
  padding: 0.5rem;
  display: inline-block;
  font-size: 2rem;
  margin-bottom: 1.5rem;
} */
/* .skill-text {
  max-width: 20rem;
  margin-left: auto;
  margin-right: auto;
} */
.skill-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 10rem; */
}

.skill-info img {
  /* height: 2vw;
  width: 2vw;
  margin-bottom: 0.5rem; */
}

.skills-center {
  
}
@media screen and (min-width: 1px) {
  .skills-center {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    justify-content: center;
  }
  .skill-info img {
    height: 10vw;
    width: 10vw;
    margin-bottom: 0.5rem;
  }
}

@media screen and (min-width: 576px) {
  .skills-center {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    justify-content: center;
  }
  .skill {
    margin-bottom: 0rem;
    place-self: center;
  }
  /* .skill-info img {
    height: 1vw;
    width: 1vw;
    margin-bottom: 0.5rem;
  } */
}
@media screen and (min-width: 992px) {
  .skills-center {
    grid-template-columns: 10rem 10rem 10rem 10rem;
    
  }
  .skill-info img {
  height: 2vw;
  width: 2vw;
  margin-bottom: 0.5rem;
}
}
@media screen and (min-width: 1170px) {
  .skill {
    /* display: flex;
    text-align: left; */
  }
  /* .skill-icon {
    align-self: start;
  } */

  .skill-title {
    margin-bottom: 0.5rem;
  }
}

/*
=============== 
Projects
===============
*/
.projects {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: var(--transition);
  background: var(--clr-grey-10);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}
.project-card:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transform: scale(1.02);
}
.project-img-container {
  position: relative;
}
.project-img-container a{
  display: flex;
  flex-direction: column;
  align-items: center;

}

.project-img {
  /* height: 15rem;
  object-fit: cover; */
  margin-top: 0.5rem;
  width: 100%;
  height: auto;  
}
/* .project-date {
  position: absolute;
  right: 0;
  bottom: 0;
  background: var(--clr-primary-8);
  color: var(--clr-primary-1);
  text-transform: capitalize;
  padding: 0.25rem 0.5rem;
  margin-bottom: 0;
} */
.project-info {
  display: flex;
   flex-direction: column;
  align-items: center;
  padding: 1.25rem 1.5rem;
}

.project-card a{
  margin-top: 0.7rem;
  margin-bottom: -0.5rem;
  text-decoration:underline;
}



/* additional css for different layout*/
.project-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  /* flex-wrap: wrap; */
}
.project-title a{
  margin-top: -0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-title p,
.project-title h4 {
  /* margin-top: 0.75rem; */
  margin-bottom: 0.75rem;
  line-height: 1.25;
}
.project-title p {
  color: var(--clr-grey-7);
}
/* end of additional css for different layout*/
.project-footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.project-footer a {
  /* margin-top: 0.0rem; */
  margin-bottom: 0.2rem;
  text-decoration:underline;
}
.project-footer p {
  line-height: 0;
  margin-top: -0.5rem;
  margin-bottom: 0;
  text-transform: capitalize;
  color: var(--clr-primary-5);
}
/* .project-footer p span {
  margin-right: 0.25rem;
} */
/* .project-btn {
  text-align: center;
} */
@media screen and (min-width: 768px) {
  .projects-center {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
}
@media screen and (min-width: 1170px) {
  .projects-center {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

/*
=============== 
Footer
===============
*/
.footer {
  background: var(--clr-grey-1);
  text-align: center;
  /* padding: 3rem ; */
  padding-left: 2rem; 
  padding-right: 2rem;
}
.footer-links,
.footer-icons {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
}
.footer-link {
  color: var(--clr-white);
  text-transform: capitalize;
  font-size: 1rem;
  margin-right: 1rem;
  letter-spacing: var(--spacing);
  transition: var(--transition);
}
.footer-link:hover {
  color: var(--clr-primary-5);
}
.footer-icon {
  font-size: 2rem;
  margin-right: 1rem;
  color: var(--clr-white);
  transition: var(--transition);
}
.footer-icon:hover {
  color: var(--clr-primary-5);
}
.copyright {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-transform: capitalize;
  letter-spacing: 0.25rem;
  color: white;
}
.copyright a{
  color: white;
  text-transform: lowercase;
  text-decoration: underline;
}
.copyright span {
  margin-left: 0.5rem;
}
/*
=============== 
Navbar
===============
*/
/* add :not(.nav-logo) to img */

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--clr-white);
  box-shadow: var(--dark-shadow);
  z-index: 2;
  height: 4rem;
  display: flex;
  align-items: center;
}
.nav-icons {
  display: none;
}
.nav-center {
  width: 90vw;
  /* max-width: 1170px; */
  margin: 0 auto;
  /* display: flex; */
  /* justify-content: space-around; */
  /* align-items: center; */
}
.nav-header {
   display: flex;
  justify-content: space-around;
  align-items: center;
}
.nav-logo {
  margin-right: 1rem;
}
.nav-logo-img{
  width: 5vw;
}
.nav-name {
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 1rem;
}

.nav-toggle {
  background: transparent;
  border: none;
  outline: none;
  font-size: 1.5rem;
  color: var(--clr-primary-5);
  cursor: pointer;
  transition: var(--transition);
}
.nav-toggle:hover {
  transform: scale(1.2);
}
.nav-link {
  display: block;
  padding: 1rem 2rem;
  text-transform: capitalize;
  letter-spacing: var(--spacing);
  transition: var(--transition);
  color: var(--clr-grey-1);
  cursor: pointer;
  font-size: 1rem;
}
.nav-link:hover {
  color: var(--clr-primary-1);
  background: var(--clr-primary-8);
  padding-left: 2.25rem;
}
/* nav toggle functionality */
.nav-links {
  height: 0;
  overflow: hidden;
  /* transition: all 0.1s ; */
  cursor: pointer;
}
.show-links {
  cursor: pointer;
  position: fixed;
  height: 280px;
  background-color: var(--clr-grey-9);
  transition: all 0.1s linear;
  box-shadow: 1px 1px 4px var(--clr-grey-1);
}
/* nav at bigger screen size */
@media screen and (min-width: 992px) {
  /* hide nav toggle button */
  .nav-toggle {
    display: none;
  }
  /* show links */
  .nav-links {
    height: auto;
    display: flex;
  }
  .nav-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* optional */
    flex-wrap: wrap;
  }
  .nav-header {
    padding: 0 0;
  }
  .nav-link {
    padding: 0 0;
  }
  .nav-link:hover {
    padding: 0;
    color: var(--clr-primary-5);
    background: transparent;
  }
  .nav-icons {
    display: flex;
  }

  .nav-link {
    margin-right: 0.7rem;
  }
  .nav-icon {
    margin-right: 0.7rem;
    color: var(--clr-primary-5);
    font-size: 1.2rem;
    transition: var(--transition);
  }
  .nav-icon:hover {
    color: var(--clr-primary-8);
  }
}
/* set hero to 
min-height:calc(100vh - 62px);
*/
